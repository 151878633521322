import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Footer from "../components/Footer";
import BlogCard from "../components/BlogCard";
import { Helmet } from "react-helmet";

function BlogDetails() {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [latestPosts, setLatestPosts] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        // Fetch the current post
        const response = await axios.get(
          `https://public-api.wordpress.com/rest/v1.1/sites/alleshealth.wordpress.com/posts/slug:${slug}`
        );
        if (response?.status === 200) {
          setPost(response.data);
        }

        // Fetch latest posts
        const latestResponse = await axios.get(
          `https://public-api.wordpress.com/rest/v1.1/sites/alleshealth.wordpress.com/posts/?order=desc`
        );
        if (latestResponse?.status === 200) {
          const currentPostId = response?.data?.ID;
          const filteredPosts = latestResponse.data.posts.filter(
            (item) => item.ID !== currentPostId
          );
          setLatestPosts(filteredPosts);
        }
      } catch (error) {
        console.error("Error while fetching post details", error);
      } finally {
        setLoading(false);
      }
    })();
  }, [slug]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-sky-500"></div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-gray-600 text-xl">Post not found</div>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{post?.title}</title>
        <meta
          name="description"
          content={post?.excerpt.replace(/<p>|<\/p>/g, "")}
        />
        <meta
          name="keywords"
          content={`${post?.title.split(" ").join(", ")}, ${
            post?.categories ? Object.keys(post.categories).join(", ") : ""
          }, blog, health, wellness, alleshealth`} // Dynamic keywords
        />
      </Helmet>
      {/* Hero Header Section */}
      <div
        className="relative h-screen bg-gray-800 bg-opacity-50 mb-8 overflow-hidden"
        style={{
          backgroundImage: `url(${
            post?.featured_image || "https://via.placeholder.com/1200x600"
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>

        <div className="relative z-10 max-w-6xl mx-auto flex flex-col items-start justify-end pb-40 h-full px-4">
          <h1 className="text-4xl md:text-6xl lg:text-8xl font-bold text-white leading-tight mb-4">
            {post?.title}
          </h1>

          <p className="text-white text-sm md:text-lg">
            {new Date(post?.date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}{" "}
            • Written by {post?.author?.name || "Admin"}
          </p>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Content Section */}
        <article className="prose prose-lg max-w-none">
          <div
            className="text-gray-700 leading-relaxed"
            dangerouslySetInnerHTML={{ __html: post?.content }}
          />
        </article>

        {/* Meta Information */}
        <div className="mt-8 pt-8 border-t border-gray-200">
          <div className="flex flex-col sm:flex-row justify-between items-center text-sm text-gray-600">
            {post.author && (
              <div className="mb-4 sm:mb-0">
                Written by{" "}
                <span className="font-medium text-gray-900">
                  {post?.author?.name}
                </span>
              </div>
            )}
            <div>
              Last modified:{" "}
              {new Date(post?.modified).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
            </div>
          </div>
        </div>
      </div>
      {/* Recent Updates Section */}
      <div className="mt-12">
        {latestPosts.length > 0 && (
          <>
            <div className="text-center text-gray-800 text-2xl font-medium mb-10">
              Recent Updates
            </div>
            <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-14">
              {latestPosts.slice(0, 3).map((latestPost, i) => (
                <BlogCard key={i} post={latestPost} />
              ))}
            </div>
          </>
        )}
      </div>
      <div className="mt-24">
        <Footer />
      </div>
    </div>
  );
}

export default BlogDetails;
