import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import BlogSlider from "../components/BlogSlider";
import BlogCard from "../components/BlogCard";
import axios from "axios";
import { Helmet } from "react-helmet";

function Blogs() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          "https://public-api.wordpress.com/rest/v1.1/sites/alleshealth.wordpress.com/posts"
        );
        if (response?.status === 200) {
          setPosts(response?.data?.posts);
        }
      } catch (error) {
        setPosts([]);
        console.error("Error while fetching posts", error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Blogs - AllesHealth</title>
        <meta
          name="description"
          content={posts?.[0]?.excerpt.replace(/<p>|<\/p>/g, "")}
        />
        <meta
          name="keywords"
          content="health, blogs, AllesHealth, wellness, fitness"
        />
      </Helmet>
      <div className="text-center text-sky-500 text-2xl font-semibold my-10">
        AllesHealth Blogs
      </div>

      {/* Higlighted Post */}
      {false && (
        <div className="max-w-6xl mx-auto bg-white border shadow-md rounded-lg overflow-hidden px-10 py-14">
          <div className="flex space-x-6">
            <img
              className="w-1/2 h-96 rounded-2xl object-cover"
              src="https://images.unsplash.com/photo-1620389702593-ca133a35cae9?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            />
            <div className="w-1/3">
              <div className="flex items-center space-x-10">
                <div className="text-neutral-300 text-base font-bold">
                  20th August 2024
                </div>
                <div className="text-neutral-300 text-base font-bold">
                  3-5 min(s) read
                </div>
              </div>
              <div className="text-gray-800 text-2xl font-semibold mt-9">
                Heading 1 describes the content
              </div>
              <div className="text-gray-800 text-xs font-light mt-3">
                Increasing business success involves a multifaceted approach
                that encompasses various strategies tailored to specific needs
                and objectives. Firstly, maintaining a keen focus on customer
                satisfaction is paramount. By understanding and meeting the
                needs of your target audience, you can foster loyalty and
                attract new customers through positive word-of-mouth.
                Additionally, investing in marketing efforts, both traditional
                and digital, can help expand your reach and visibility in the
                market.
              </div>
              <div className="bg-white rounded-3xl shadow border border-sky-400 text-center text-sky-500 text-xl font-semibold mt-12 py-4 cursor-pointer hover:scale-105 hover:shadow-lg hover:shadow-sky-200 active:scale-95 transition-all duration-200">
                Read more
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-14">
        {loading
          ? [...Array(7)].map((_, i) => (
              <div className="bg-white border rounded-2xl shadow-lg overflow-hidden select-none animate-pulse">
                {/* Skeleton for the image */}
                <div className="aspect-video bg-gray-200"></div>

                <div className="p-5">
                  {/* Skeleton for the title */}
                  <div className="h-6 bg-gray-200 rounded-lg w-3/4 mb-2"></div>

                  {/* Skeleton for the date */}
                  <div className="h-4 bg-gray-200  rounded-lg w-1/3 mb-4"></div>

                  {/* Skeleton for the excerpt */}
                  <div className="h-12 bg-gray-200  rounded-2xl w-full mb-4"></div>

                  {/* Skeleton for the divider */}
                  <div className="h-px w-1/3 bg-gray-200 my-2"></div>

                  {/* Skeleton for the "Read More" button */}
                  <div className="flex items-center space-x-0.5">
                    <div className="h-4 bg-gray-200 rounded-lg w-16"></div>
                    <div className="p-1 bg-gray-200 rounded-full">
                      <div className="w-2 h-2 bg-gray-300 rounded-full"></div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          : posts?.map((post, i) => <BlogCard key={i} post={post} />)}
      </div>

      <div className="mt-24">
        <Footer />
      </div>
    </div>
  );
}

export default Blogs;
